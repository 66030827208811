.maplibregl-popup-content {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  width: auto !important;
  background-color: none !important;
}

.maplibregl-popup {
  max-width: none !important;
}