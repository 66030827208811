@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Roboto-Bold.ttf")  format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
  position: fixed; 
  transition: background 0.2s ease-in, color 0.2s ease-in;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #00857C;
  text-decoration: none;
}

* {
  box-sizing: border-box !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color:#d1d3d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #08C296;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: 100svh;
}