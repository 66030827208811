.maplibregl-ctrl-bottom-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 3;
  width: 100%;
}
.maplibregl-ctrl-bottom-right > * {
  grid-column-start: 2;
  justify-self: end;
}

.maplibregl-ctrl-bottom-right > .legend {
  grid-row-start: 3;
  grid-column-start: 1;
  align-self: end;
  justify-self: start;
  margin-left: 10px;
  margin-bottom: 10px;
  pointer-events: auto;
}

.maplibregl-ctrl-bottom-right > *:last-child {
  grid-column-start: 1;
  grid-column-end: 3;
}
